import "antd/dist/antd.less";
import "./Landing.less";
import logo from "../images/logo/TARJM_Logo_Emblem_Above_Name_With_Tag.svg";
import signInButton from "../images/linkedin/retina/Sign-In-Large---Default.png";

import Footer from "./Footer";

const backend_url =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

const Landing = () => {
  return (
    <div className="Landing">
      <div className="Main">
        <div className="Elements">
          <img className="Logo" src={logo} alt="Tarjm Logo" />
          <h1 className="Tagline">All your construction needs in one place.</h1>
          <a className="Sign-In-Link" href={`${backend_url}/api/auth/linkedin`}>
            <img
              className="Sign-In"
              src={signInButton}
              alt="Sign in with Linkedin"
            />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
