import { NavLink } from "react-router-dom";

import "./Footer.less";
import logo from "../images/logo/TARJM_Logo.svg";

const date = new Date();

const Footer = () => {
  return (
    <div className="Footer">
      <img className="Small-Logo" src={logo} alt="small tarjm logo" />
      <span className="Footer-Copyright">&copy; {date.getFullYear()}</span>
      <NavLink className="Footer-Link" to="#">
        About
      </NavLink>
      <NavLink className="Footer-Link" to="#">
        Blog
      </NavLink>
      <NavLink className="Footer-Link" to="#">
        Privacy Policy
      </NavLink>
      <NavLink className="Footer-Link" to="#">
        Cookie Policy
      </NavLink>
      <NavLink className="Footer-Link" to="#">
        User Agreement
      </NavLink>
    </div>
  );
};

export default Footer;
